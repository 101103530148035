@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@700&family=Tajawal:wght@500&display=swap");

* {
  font-family: "Almarai", sans-serif;
  font-family: "Tajawal", sans-serif;
}

a {
  text-decoration: none !important;
}

.rounded-x {
  border-radius: 20px;
}

html,
body {
  scroll-behavior: smooth !important;
  /* overflow-x: hidden; */
  /* width: 100vw; */
  overflow-x: hidden;
}

.Profile {
  /* justify-content: center; */
  justify-content: space-between;
}

.side__left h4 {
  font-size: 15px;
}

.Profile .side__left {
  background-image: url("./assets/profile/Rectangle 292.svg");
  height: 50px;
  justify-content: center;
  width: 18rem;
  align-items: center;
  margin-bottom: 0;
  align-self: flex-end;
  gap: 10px;
  border-bottom-right-radius: 15px;
}
.side__left h5 {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
}
.side__left h4 {
  margin-left: 1.5rem;
}

.side__left__content {
  flex-direction: column;
  width: 50%;
  gap: 30px;
}

@media screen and (max-width: 676px) {
  .Profile .side__left {
    display: flex;
    flex-direction: column;
    height: 70px;
  }
}

@media screen and (max-width: 667px) {
  .Profile {
    flex-direction: column;
  }
  .Profile .side__left__content {
    align-self: center;
    width: 100%;
  }

  .Profile .side__left__content .img {
    width: 100%;
  }
}

.profile__title {
  border-radius: 10px;
  color: #11bfbf !important;
  text-align: center;
  font-size: 15px !important;
  padding: 5px !important;
}

.profile_card .card-title:hover {
  user-select: none;
}

.profile_card {
  border: 8px solid rgba(0, 0, 0, 0.103) !important;
  border-radius: 30px !important;
  transition: border 0.6s linear !important;
}

.profile_card:hover {
  border-color: #11bfbf !important ;
}

.profile_card_hover {
  border-color: #11bfbf !important ;
}

@media screen and (max-width: 992px) {
  .Element {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.side__left__content {
  flex: 1 !important;
}

.side__left__content .img {
  margin-bottom: 50px;
}

iframe {
  margin: 0 !important;
}

table {
  overflow: scroll;
}

table tr td,
table tr th {
  border: 1px solid #1a1a1a44;
  font-weight: 400 !important;
}
table tr td,
table tr th {
  min-width: -webkit-max-content;
  min-width: max-content;
  text-align: center;
}

.login-button {
  background: #00a5a5;
  border: transparent;
}

.imagesquistions {
  width: 200px;
  height: 300px;
  object-fit: cover;
  margin: 10px;
}

.main-input {
  border-radius: 1rem;
  padding: 0.5rem;
}
