/* style of scrollbar button */

::-webkit-scrollbar-button {
    height: 10%;
}

/* style of scrollbar track   */
::-webkit-scrollbar-track {
    background-color: #235c70;
    border-radius: 5px;
}

/* style of scrollbar   */
::-webkit-scrollbar {
    width: 15px;
}

/* style of scrollbar thumb */
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #11aea185;
}

body {
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
}

.sideBar__relative {
    position: relative;
    /* width: 100%; */
}

a {
    text-decoration: none;
    list-style: none;
}

.sideBar__user,
.side__content {
    text-decoration: none;
}

.progress-bar {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

.progress-bar-fill {
    height: 20px;
    background-color: #4caf50;
    transition: width 0.2s;
}

.btn.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.header-sec {
    background-image: linear-gradient(to bottom, #2ba9a6, #1e7876);
    justify-content: space-between;
}

.text {
    font-size: larger;
    color: black;
}

@media only screen and (max-width: 768px) {
    .text {
        font-size: small;
        color: black;
    }
}

.img {
    width: 50px;
    height: 50px;
}

@media only screen and (max-width: 768px) {
    .img {
        width: 50px;
        height: 50px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.text-pp {
    font-size: medium;
    color: #595959;
}

@media only screen and (max-width: 768px) {
    .text-pp {
        font-size: smaller;
        color: #595959;
    }
}

.border {
    border-style: solid;
    border-width: 5px;
    border-width: 80%;
    margin: auto;
}

.text-ppp {
    font-size: medium;
    color: #595959;
}

.text-pppp {
    font-size: large;
    color: black;
}

.box-with-p {
    border-radius: 25px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 5px;
    font-size: small;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .text-ppp {
        font-size: x-small;
        color: #595959;
    }
}

@media only screen and (max-width: 768px) {
    .text-pppp {
        font-size: small;
        color: black;
    }
}

@media only screen and (max-width: 768px) {
    .message-width {
        width: 100%;
    }

    .messsage_div {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .message-text {
        font-size: 8px;
    }
}

.color-mes-send {
    border: 2px solid white;
    border-radius: 10px;
}

.color-mes-resend {
    border: 2px solid rgb(214, 200, 200);
    border-radius: 10px;
}

.image_section {
    border: 2px solid black;
    border-radius: 10px;
    max-width: 50%;
    max-height: 150px;
}

@media only screen and (max-width: 600px) {
    .image_section {
        border: 2px solid black;
        border-radius: 10px;
        max-width: 50%;
        max-height: 120px;
    }
}

.div_image_section {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .div_image_section {
        display: flex;
        justify-content: start;
        align-items: center;
    }
}

.mes {
    height: 7vh;
}

.dir_right {
    text-align: right;
}

.dir_left {
    text-align: left;
}

.spa {
    top: 0px;
    right: -15px;
    border: solid #DCF8C6 15px;
    border-color: #DCF8C6 transparent transparent #DCF8C6;

    /* border-left: none;
    border-top: solid #effdde 15px;
    border-bottom: none;
    border-right: solid #effdde 15px; */
    /* border-bottom-left-radius: 10px;
    border-top-left-radius: 40px; */
}

.spaa {
    top: 0px;
    left: -15px;
    border: solid #F5F5DC 15px;
    border-color: #F5F5DC #F5F5DC transparent transparent;
    /* border-left: none;
    border-top: solid #effdde 15px;
    border-bottom: none;
    border-right: solid #effdde 15px; */
    border-bottom-right-radius: 100px;
    border-top-right-radius: 10px;
}

.curser {
    cursor: pointer;
}

.textare {
    outline: none;
    border-color: white;
    outline: none;
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 6vh;
    scrollbar-width: none;
    resize: none;
}

.mes_div {
    position: relative;
    margin: 150px;
}

@media only screen and (max-width: 600px) {
    .mes_div {
        position: relative;
        margin-top: 150px;
    }
}

.rep {
    border: solid 1px #100341;
    z-index: 20000;
    border-radius: 10px;
    border-bottom: none;
    position: fixed;
    bottom: 7%;
}

@media only screen and (max-width: 1000px) {
    .rep {
        border: solid 1px #75af2f;
        padding: 2px;
        border-radius: 10px;
        border-bottom: none;
        position: fixed;
        bottom: 7%;
        overflow: hidden;
    }
}

@media only screen and (max-width: 600px) {
    .rep {
        border: solid 1px #75af2f;
        padding: 2px;
        border-radius: 10px;
        border-bottom: none;
        position: fixed;
        bottom: 5%;
        width: 90%;
    }
}

.mes {
    border: solid 1px #7d995c;
    position: fixed;
    bottom: 0%;
}

@media only screen and (max-width: 600px) {
    .mes {
        width: 95%;
        max-height: 5vh;
    }

    .textare {
        border-inline-color: white;
        border-color: white;
        outline: none;
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        height: 4vh;
        margin-right: 3px;
    }
}

.index {
    z-index: 2;
}

.b_li {
    text-align: center;
    color: black;
    padding: 4px;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.b_li:hover {
    background-color: white;
    color: #595959;
    border-radius: 10px;
    cursor: pointer;
}

.close {
    text-align: center;
    text-justify: center;
    font-size: large;
    color: red;
    background: white;
    font-weight: bold;
}

.record_dev {
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    background-color: #3cb5b0;
    margin: auto;
    position: fixed;
    bottom: 7%;
    left: 17%;
    border-radius: 10px;
}

.rec_text {
    font-weight: bold;
    font-size: medium;
}

@media only screen and (max-width: 600px) {
    .record_dev {
        z-index: 99999;
        left: 0;
        width: 95%;
        margin: auto;
    }
}

.ul-left-text-message {
    width: 120px;
    background-color: rgb(202, 191, 191);
    font-size: 14px;
    font-weight: bold;
    list-style: none;
    padding: 5px;
    flex: content;
    border-radius: 10px;
    flex: auto;
    position: absolute;
    top: -50px;
    left: 5%;
}

.ul-left-image-message {
    width: 120px;
    background-color: rgb(202, 191, 191);
    font-size: 14px;
    font-weight: bold;
    list-style: none;
    padding: 5px;
    flex: content;
    border-radius: 10px;
    flex: auto;
    position: absolute;
    top: -25px;
    left: 5%;
    z-index: 1;
}

.ul-left-audio-message {
    width: 120px;
    background-color: rgb(202, 191, 191);
    font-size: 14px;
    font-weight: bold;
    list-style: none;
    padding: 5px;
    flex: content;
    border-radius: 10px;
    flex: auto;
    position: absolute;
    top: -30px;
    left: 5%;
    z-index: 1;
}

.ul-right-text-message {
    width: 120px;
    background-color: rgb(202, 191, 191);
    font-size: 14px;
    font-weight: bold;
    list-style: none;
    padding: 3px;
    flex: content;
    border-radius: 10px;
    flex: auto;
    position: absolute;
    top: -50px;
    right: 5%;
}

.ul-right-image-message {
    width: 120px;
    background-color: rgb(202, 191, 191);
    font-size: 14px;
    font-weight: bold;
    list-style: none;
    padding: 3px;
    flex: content;
    border-radius: 10px;
    flex: auto;
    position: absolute;
    top: -25px;
    right: 5%;
    z-index: 1;
}

.ul-right-audio-message {
    width: 120px;
    background-color: rgb(202, 191, 191);
    font-size: 14px;
    font-weight: bold;
    list-style: none;
    padding: 3px;
    flex: content;
    border-radius: 10px;
    flex: auto;
    position: absolute;
    top: -30px;
    right: 30%;
    z-index: 1;
}

@media only screen and (max-width: 1000px) {
    .ul-right-text-message {
        width: 80px;
        background-color: rgb(202, 191, 191);
        font-size: 12px;
        font-weight: bold;
        list-style: none;
        padding: 1px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -50px;
        right: 7%;
    }

    .ul-right-image-message {
        width: 80px;
        background-color: rgb(202, 191, 191);
        font-size: 12px;
        font-weight: bold;
        list-style: none;
        padding: 1px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -25px;
        right: 7%;
    }

    .ul-left-text-message {
        width: 80px;
        background-color: rgb(202, 191, 191);
        font-size: 12px;
        font-weight: bold;
        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -50px;
        left: 7%;
    }

    .ul-left-image-message {
        width: 80px;
        background-color: rgb(202, 191, 191);
        font-size: 12px;
        font-weight: bold;
        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -25px;
        left: 7%;
    }
}

@media only screen and (max-width: 600px) {
    .ul-right-text-message {
        width: 80px;
        background-color: rgb(202, 191, 191);
        font-size: 10px;
        font-weight: bold;
        list-style: none;
        padding: 1px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -40px;
        right: 10%;
    }

    .ul-right-image-message {
        width: 80px;
        background-color: rgb(202, 191, 191);
        font-size: 10px;
        font-weight: bold;
        list-style: none;
        padding: 1px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -20px;
        right: 10%;
    }

    .ul-left-text-message {
        width: 80px;
        background-color: rgb(202, 191, 191);

        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -40px;
        left: 10%;
    }

    .ul-left-image-message {
        width: 80px;
        background-color: rgb(202, 191, 191);

        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -20px;
        left: 10%;
    }
}

.ul_left {
    width: 120px;
    background-color: rgb(202, 191, 191);

    list-style: none;
    padding: 5px;
    flex: content;
    border-radius: 10px;
    flex: auto;
    position: absolute;
    top: -10px;
    left: 29%;
}

.ul_right {
    width: 120px;
    background-color: rgb(202, 191, 191);

    list-style: none;
    padding: 5px;
    flex: content;
    border-radius: 10px;
    flex: auto;
    position: absolute;
    top: -10px;
    right: 29%;
}

@media only screen and (max-width: 1000px) {
    .ul_right {
        width: 80px;
        background-color: rgb(202, 191, 191);

        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: 10px;
        right: 57%;
    }

    .ul_left {
        width: 80px;
        background-color: rgb(202, 191, 191);

        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: 10px;
        left: 59%;
    }
}

@media only screen and (max-width: 600px) {
    .ul_right {
        width: 80px;
        background-color: rgb(202, 191, 191);

        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -20px;
        right: 75%;
    }

    .ul_left {
        width: 80px;
        background-color: rgb(202, 191, 191);

        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: 10px;
        left: 59%;
    }
}

.ul_left_img {
    width: 120px;
    background-color: rgb(202, 191, 191);
    font-size: 14px;
    font-weight: bold;
    list-style: none;
    padding: 5px;
    flex: content;
    border-radius: 10px;
    flex: auto;
    position: absolute;
    top: -36px;
    left: 5%;
}

.ul_right_img {
    width: 120px;
    background-color: rgb(202, 191, 191);
    font-size: 14px;
    font-weight: bold;
    list-style: none;
    padding: 3px;
    flex: content;
    border-radius: 10px;
    flex: auto;
    position: absolute;
    top: -36px;
    right: 5%;
}

@media only screen and (max-width: 1000px) {
    .ul_right_img {
        width: 80px;
        background-color: rgb(202, 191, 191);
        font-size: 12px;
        font-weight: bold;
        list-style: none;
        padding: 1px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -30px;
        right: 20%;
    }

    .ul_left_img {
        width: 80px;
        background-color: rgb(202, 191, 191);
        font-size: 12px;
        font-weight: bold;
        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -30px;
        left: 20%;
    }
}

@media only screen and (max-width: 600px) {
    .ul_right_img {
        width: 80px;
        background-color: rgb(202, 191, 191);

        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -35px;
        right: 25%;
    }

    .ul_left_img {
        width: 80px;
        background-color: rgb(202, 191, 191);

        list-style: none;
        padding: 5px;
        flex: content;
        border-radius: 10px;
        flex: auto;
        position: absolute;
        top: -35px;
        left: 25%;
    }
}

@media only screen and (max-width: 600px) {
    .rec_text {
        font-weight: bold;
        font-size: x-small;
    }
}

.popupDelete {
    width: 100%;
    height: 100%;
    background-color: #222;
    opacity: 90%;
    z-index: 120;
    position: fixed;
    top: 0%;
}

.popupDelete_1 {
    height: 20%;
    background-color: white;
    width: 100%;
    z-index: 130;
    position: fixed;
    top: 40%;
    left: 35%;
    border-radius: 15px;
    translate: 50px 50px;
}

@media only screen and (max-width: 1000px) {
    .popupDelete_1 {
        height: 20%;
        background-color: #e4d6d6;
        width: 100%;
        z-index: 130;
        position: fixed;
        top: 40%;
        right: 40%;
        border-radius: 15px;
        translate: 40px 40px;
    }
}

@media only screen and (max-width: 600px) {
    .popupDelete_1 {
        height: 20%;
        background-color: #e4d6d6;
        width: 70%;
        z-index: 130;
        position: fixed;
        top: 40%;
        right: 25%;
        border-radius: 15px;
        translate: 40px 40px;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.rotating-icon {
    animation: rotate 1s linear infinite;
}

.last-seen {
    padding-right: 70px;
    margin-top: -14px;
    margin-bottom: 4px !important;
    font-size: 16px;
    color: #30282d;
}

@media only screen and (max-width: 600px) {
    .last-seen {
        padding-right: 80px;
        margin-top: -14px;
        margin-bottom: 4px !important;
        font-size: 12px;
        font-weight: bold;
        color: #30282d;
    }
}

.chat-header {
    position: fixed;
    top: 0;
    z-index: 1000;
    padding: 2px 0;
    box-shadow: 0 4px 2px -2px transparent;
    transition: box-shadow 0.3s ease-in-out;
    height: 180px;
    /* Assuming the height of the header */
    width: 100%;
    /* Ensuring the header spans the full width */
}

.content-below {
    margin-top: 180px;
}

.message {
    background: #bbb;
}

.img_replay {
    width: 5%;
    /* max-height: 60px; */
    border-radius: 15px;
}

@media only screen and (max-width: 1000px) {
    .img_replay {
        width: 30%;
        max-height: 70px;
        border-radius: 15px;
    }
}

.gap {
    gap: 10px;
}

.img_load {
    width: 70px;
    height: 60px;
    border-radius: 100%;
}

.img_div {
    object-fit: contain;
}

@media (max-width: 600px) {
    .chat-header {
        height: 60px;
    }

    .content-below {
        margin-top: 60px;
    }
}

@media (min-width: 601px) {
    .chat-header {
        height: 100px;
        /* Adjust as necessary */
    }

    .content-below {
        margin-top: 180px;
        /* Adjust as necessary */
    }
}

.audio {
    width: 30%;
}

@media (max-width: 1000px) {
    .audio {
        width: 60%;
    }
}

@media (max-width: 600px) {
    .audio {
        width: 96%;
    }
}

.player_div {
    width: 100%;
    border: 2px solid black;
    border-radius: 10px;
    background-color: green;
    padding: 5px;
    max-height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 600px) {
    .player_div {
        width: 100%;
        border: 2px solid black;
        border-radius: 10px;
        background-color: green;
        padding: 5px;
        max-height: 8vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: x-small;
    }

    .player_img {
        max-width: 30px;
        max-height: 30px;
        border: 2px solid white;
        border-radius: 100%;
    }
}

.player_img {
    max-width: 40px;
    max-height: 40px;
    border: 2px solid white;
    border-radius: 100%;
}

.audio-player {
    background-color: #d4e9e8 !important;
    border-radius: 9px;
    direction: rtl;
    margin-top: 5px;
}

@media (max-width: 600px) {
    .sec {
        margin-top: 150px;
    }
}

.img_rec {
    width: 50px;
    height: 50px;
    border: 2px solid white;
    border-radius: 100%;
}

.progress-loader {
    text-align: center;
    position: absolute;
    color: white;
    font-weight: 600;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.progress-div-audio {
    position: absolute;
    background-color: #1f7d7b9e;
    border-radius: 200px;
    left: 50%;
    top: 50%;
    width: 5%;
    transform: translate(-50%, -50%);
}

@media (max-width: 1024px) {
    .progress-div-audio {
        position: absolute;
        background-color: #1f7d7b9e;
        border-radius: 200px;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
        width: 10%;
    }
}

@media (max-width: 760px) {
    .progress-div-audio {
        position: absolute;
        background-color: #1f7d7b9e;
        border-radius: 200px;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
        width: 15%;
    }
}

@media (max-width: 440px) {
    .progress-div-audio {
        position: absolute;
        background-color: #1f7d7b9e;
        border-radius: 200px;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
        width: 24%;
    }
}

.progress-div {
    position: absolute;
    background-color: #1f7d7b9e;
    border-radius: 200px;
    left: 50%;
    top: 50%;
    width: 20%;
    transform: translate(-50%, -50%);
}

.image-width {
    width: 40%;
}

.audio-width {
    width: 90%;
}

@media (max-width: 1024px) {
    .progress-div {
        position: absolute;
        background-color: #1f7d7b9e;
        border-radius: 200px;
        left: 50%;
        top: 50%;
        width: 20px;
        transform: translate(-50%, -50%);
        width: 25%;
    }

    .image-width {
        width: 75%;
    }
}

@media (max-width: 660px) {
    .progress-div {
        position: absolute;
        background-color: #1f7d7b9e;
        border-radius: 200px;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
        width: 35%;
    }
}

@media (max-width: 400px) {
    .progress-div {
        position: absolute;
        background-color: #1f7d7b9e;
        border-radius: 200px;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
        width: 45%;
    }
}

.audio .progress-loader {
    font-size: 11px;
}

.image-rtl-options {
    position: absolute;
    top: 7px;
    right: 7px;
    border-radius: 50px;
    background-color: #f0f8ff61;
    z-index: 101;
}

.image-ltr-options {
    position: absolute;
    top: 7px;
    left: 7px;
    border-radius: 30px;
    background-color: #f0f8ff61;
    z-index: 101;
}

.img-container {
    width: 100%;
    max-height: 550px;
    overflow: hidden;
    position: relative;
}

.file .img-container {
    height: unset;
}

.img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.audio-container {
    width: 100%;
    height: 100px;
    overflow: hidden;
}

.modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.modal-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
}

.message-div {
    transition: opacity 0.5s ease-in-out 0.3s;
    opacity: 1;
}

.message-div.hidden {
    opacity: 0;
}

.highlighted {
    background-image: radial-gradient(circle,
            rgb(38 150 147) 0%,
            rgba(0, 187, 91, 0.2049194677871149) 74%);
    opacity: 1;
    /* Ensure it becomes fully opaque when highlighted */
}

.chat-img {
    width: 12%;
    border-radius: 10px;
    border: "1px solid blue";
}

.shimmer-avatar {
    margin-bottom: 2px !important;
    margin-top: 2px;
}

.shimmer-title--secondary {
    margin-bottom: 2px !important;
    width: 13%;
}

.c-shimmer {
    background: linear-gradient(to right, #25918f70 8%, #f0f0f0 18%, #238a8780 33%) !important;

}

.online_div {
    position: relative;
}

.online {
    width: 18px;
    height: 18px;
    border: 1px solid white;
    border-radius: 100%;
    background-color: green;
    position: absolute;
    bottom: -5px;
    left: 4%;
}

.message-text-right {
    gap: 10px;
    margin-right: 15px;
}

.message-text-left {
    gap: 10px;
    margin-left: 15px;
}

.div_replay_text {
    display: flex;
    justify-content: start;
    margin-top: 0;
    /* background-color: red; */
    margin-right: 50px;
}

.div_replay_mult {
    display: flex;
    justify-content: end;
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    /* Ensure it's above other elements */
}

.custom-modal-dialog {
    background: white;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    padding: 20px;
}

.custom-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-modal-title {
    margin: 0;
}

.custom-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.custom-modal-footer {
    margin-top: 20px;
    text-align: center;
}

.video_container {
    margin: auto;
    padding: 5px;
    border-radius: 50px;
    border: 1px solid rgb(64, 64, 141);
    width: 250px;
    height: 230px;
    object-fit: cover;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}

.video {
    border-radius: 50px;
    border: 1px solid rgb(64, 64, 141);
}

@media (max-width: 1000px) {
    .video_container {
        margin: auto;

        border-radius: 50px;
        border: 1px solid blue;
        width: 150px;
        height: 100px;
        object-fit: cover;
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
    }
}

/* Container for the button */
.lesson-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

/* Modern button styling */
.modern-button {
    background-color: #4CAF50;
    /* Green background */
    color: white;
    /* White text */
    padding: 12px 24px;
    /* Padding around the text */
    border: none;
    /* Remove default border */
    border-radius: 8px;
    /* Rounded corners */
    font-size: 16px;
    /* Font size */
    font-weight: bold;
    /* Bold text */
    cursor: pointer;
    /* Pointer cursor on hover */
    transition: background-color 0.3s, box-shadow 0.3s;
    /* Smooth transitions */
}

/* Hover effect */
.modern-button:hover {
    background-color: #45a049;
    /* Darker green on hover */
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
    /* Shadow effect on hover */
}

/* Active button effect */
.modern-button:active {
    background-color: #3e8e41;
    /* Even darker green on click */
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    /* Slightly smaller shadow */
    transform: translateY(2px);
    /* Slight button movement on click */
}

.video-responsive {
    text-align: center;
    margin-top: 5px;
}


/* Full-screen overlay for loader */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    /* Dark semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure it's on top of all other elements */
    color: white;
}

/* Container for the loader and text */
.loader-container {
    text-align: center;
}

/* Modern spinner */
.loader {
    border: 5px solid rgba(255, 255, 255, 0.2);
    border-top: 5px solid #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 0 auto 20px;
}

/* Keyframes for loader animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Modern loading text */
.loading-text {
    font-size: 1.2rem;
    color: #ffffff;
    font-weight: 400;
    margin-top: 10px;
    animation: fadeInUp 0.5s ease-in-out;
}

/* Text fade-in animation */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.modern-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modern-button {
    background: linear-gradient(135deg, #4a90e2, #007aff);
    /* Subtle gradient */
    color: white;
    padding: 15px 30px;
    font-size: 1.25rem;
    /* Slightly larger, modern font size */
    font-weight: 600;
    border: none;
    border-radius: 50px;
    /* Smoothly rounded edges */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    /* Light shadow for depth */
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    /* Smooth hover transition */
}

.modern-button:hover {
    transform: translateY(-3px);
    /* Subtle lift effect */
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    /* Stronger shadow on hover */
}

.modern-button:active {
    transform: translateY(0);
    /* Return to normal on click */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
    /* Slightly reduced shadow on click */
}

.unread-badge {
    background-color: #ff3b30;
    /* Bright red for notification */
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 50px;
    /* Rounded look */
    font-size: 0.875rem;
    font-weight: bold;
    display: inline-block;
    margin-right: 7px;
    min-width: 24px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

.subjectCardHover:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease-in-out;
}



.go-chat:hover {
    background-color: #9c7b00 !important;
    /* Darker red on hover */
}

.btn.go-chat:disabled {
    background-color: #c2c2c2;
    /* Grey color for disabled button */
    cursor: not-allowed;
    opacity: 0.6;
}








.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.2);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s, transform 0.3s;
}

.floating-button:hover {
    background-color: #0056b3;
    transform: translateY(-5px);
}

.floating-button:active {
    background-color: #004085;
}

.floating-button svg {
    width: 30px;
    height: 30px;
}

.radio-container {
    position: relative;
    display: inline-block;
    /* margin-right: 8px; */
}

.radio-container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border: 2px solid #d9534f;
    border-radius: 50%;
    font-weight: bold;
    color: #d9534f;
    cursor: pointer;
}

.radio-container input[type="radio"]:checked+.radio-label {
    background-color: #d9534f;
    color: white;
}

.small-table {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
}

.super-small-table {
    max-width: 500px;
}


.custom-alert {
    background-color: #4a99a7;
    border: 2px solid #09bc9f;
    padding: 15px;
    color: white;
    border-radius: 4px;
    text-align: center;
    max-width: 600px;
    margin: 20px auto;
    font-size: 16px;
}