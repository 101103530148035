/* 
  
  .pagination {
    width: fit-content;
  margin: 0 auto;
  background-color: #00a5a596;
  border-radius: 40px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
}
.pagination li {
  display: inline-block;
  list-style: none;
}
.pagination li button {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #00A5A5;
  text-align: center;
  text-decoration: none;
  color: #ffffff !important;
  border-radius: 4px;

  box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}
.pagination li button:hover, .pagination li button.active {
  color: #fff;
  background-color: #00a5a596;
}
.pagination li:first-child button {
  border-radius: 0 40px 40px 0;
}
.pagination li:last-child button {
  border-radius:   40px 0 0 40px;
}

.pagination .btn 
{
  padding: 0px !important;
} */




  
.pagination {
  width: fit-content;
  padding-right: 0px;
margin: 0 auto;
background-color: #00a5a596;
border-radius: 40px;
box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
}
.pagination li {
display: inline-block;
list-style: none;
}
.pagination li a {
display: block ;
width: 40px ;
height: 40px ;
line-height: 40px ;
background-color: #00A5A5;
text-align: center;
text-decoration: none;
color: #ffffff ;
border-radius: 4px;

box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.2);
transition: all 0.3s ease;
}
.pagination li a:focus-visible
{
  border: none !important;
}
.pagination li a:hover, .pagination li a.active {
color: #fff;
background-color: #00a5a596;
}
.activeitem a
{
  color: #fff !important;
background-color: #00a5a596 !important;
}

.pagination li:first-child a {
border-radius: 0 40px 40px 0;
}
.pagination li:last-child a {
border-radius:   40px 0 0 40px;
}

.pagination .btn 
{
padding: 0px !important;
}
