.question {
	margin: 20px 0px;
	color: #098b82;
	border: 1px solid rgba(0 0 0 / 18%);
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 2px 2px -1px #175e3f18, 0px 1px 2px 0px #17412f1a, 0px 1px 3px 0px #06472b11;
}

.question__container {
}
 p {
	margin-bottom: 0px !important;
 }
.question__container .topsection {
	display: flex;
	align-items: center;
	padding-bottom: 10px;
	flex-wrap: wrap;
	border-bottom: 1px solid rgba(0 0 0 / 30%);
}

.answerItself  p {
	margin: 0px !important;
}

.question__container .topsection h6 {
	margin-left: 20px;
	margin-bottom: 0px;
	min-width: fit-content;
	max-width: 70%;
	color: black;
}

.question__container .topsection span {
	color: #098b82;
	font-size: 0.8rem;
}

.question__container .answers {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	color: black;
}

.question__container .answers .answerItself {
	display: flex;
	align-items: center;
	width: fit-content;
	position: relative;
	margin: auto;
}

.question__container .answers .answerItself.rightAnswer {
	color: #26c782;
}

.question__container .answers .answerItself.wrongAnswer {
	color: #fd0202;
}

.question__container .answers .answerItself .statusImage {
	width: 1.1em;
	height: 1.1em;
	position: absolute;
	top: -3px;
	left: 5px;
	margin-right: 10px;
}

.question__container .answers .answerItself .statusImage img {
	width: 100%;
	height: 100%;
}

.question__container
	.answers
	.answerItself
	input[type='checkbox'].hidecheckbox {
	visibility: hidden;
}

.question__container .answers .answerItself input[type='checkbox'] {
	margin: 0px 5px !important;
	background-color: #fff;
	color: currentColor;
	width: 1.1em;
	height: 1.1em;
	border: 0.12em solid black;
	border-radius: 0.15em;
	transform: translateY(-0.075em);
	cursor: pointer;
	display: grid;
	place-content: center;
}

/* .question__container .answers .answerItself input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    transform: scale(1);
    background-color: CanvasText;
}

.question__container .answers .answerItself input[type="checkbox"]::before {
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]::before {
    /* ...existing styles */

/* transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  } */

.question.right .answers .answerItself input[type='checked'] {
	/* border: 1px solid #33c985;
    background-color: #2ed136; */
	/* color: black; */
	/* content: '\2713';
    color: black; */
}
