
.disable-link-effects {
    color: black;
    text-decoration: none;
}

.disable-link-effects:hover {
    color: black;
}

.image_read_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
   
}

.markAsRead {
    border: 1px solid rgba(0, 0, 0, 0.151);
    border-radius: 4px;
    margin-top: 10px;
    padding: 2px 4px;
    background: rgb(0 170 175);
    transition: all .15s ease-in;
}

.markAsRead:hover {
    background: rgb(0, 143, 148);
    border: 1px solid rgba(0, 0, 0, 0.418);
    box-shadow: -4px -7px 74px -4px rgba(0,0,0,0.75) inset;
    -webkit-box-shadow: -4px -7px 74px -4px rgba(0,0,0,0.75) inset;
    -moz-box-shadow: -4px -7px 74px -4px rgba(0,0,0,0.75) inset;
}

.markAsRead svg{
    width: 20px !important;
}