.footer-home
{
    background-image: url('../../../assets/footer/background-footer.png');
    height: fit-content; 
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
   
}


.footer-home  ul li
{
    display: inline-block;
    margin: 10px;
    list-style: none;
}

.footer-home p , .footer-home h2 ,.footer-home h3 , .footer-home a 
{
    color: white !important;
}
.footer-numbers-section
{
    width: fit-content;
    border-bottom: solid 2px #F6B41B; 
    margin: 5px auto;
    padding-bottom: 8px;
}

@media   (max-width:767px) {
    .footer-numbers-tareke-images-container  img
    {
     width: 50%;
    }
    div.footer_logo > img
    {
         width: 200px;
         height: 100px;
    }
    .footer_icons > img
    {
        width: 20px;
        height: 20px;
        margin: 4px auto;
    }
}
.footer-icon{
    color:rgba(255, 174, 0, 0.671);
    width: 35px;
    height:50px;
}

.Footer_Main_logo{
    width: 200px;
    height:100px;
}

@media screen and (max-width:992px) {
    .Footer_Main_logo{
        display:none;
    }
    .footer-numbers-tareke-images-container{
        display:none;
    }
}