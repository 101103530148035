.navbar-light  .navbar-toggler-icon
{
 
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 102, 102, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
    z-index: 2;
}
.navbar-light .navbar-toggler
{
    border:1px solid rgb(255, 153, 0) !important;

}
.navbar-toggler:focus
{
    box-shadow: 8px 4px 135px -12px rgba(255,153,0,1) !important;
    -webkit-box-shadow: 8px 4px 135px -12px rgba(255,153,0,1) !important;
    -moz-box-shadow: 8px 4px 135px -12px rgba(255,153,0,1) !important;
}
.sign
{
    background-color: #235C70 !important;

}
.sign_in
{
    border: 6px solid #235C70 !important;
    background-color: #11AEA2 !important;
    padding:2px 24px !important;
}
.navbar ul  li .sign_in:hover 
{
    background-color: #235C70 !important;
}

.notifications_icon {
    position: relative;
    cursor: pointer;
}


.notifications_container {
    position: absolute;
    /* bottom: -10px; */
    left: 0px;
    width: 300px;
    max-height: 500px ;
    overflow-y: hidden;
    border: 1px solid rgba( 0 0 0 / 35%);
    background-color: rgba(255,255,255,.9);
    backdrop-filter: blur(3px);
    z-index: 1000;
    text-align: center;
    cursor: auto !important;
    overflow-x: hidden;
    opacity: 0;
    /* display: none ; */
}

.padge_notifications {
    position: absolute;
    width: 23px;
    height: 23px;
    border: 1px solid rgba(0, 0, 0, 0.521);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 12px;
    top: -6px;
    /* z-index: -1; */
}

.notify {
    width: 100%;
    min-height: 64px;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.651);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    transition: all 115ms linear;
    border-bottom: 1px solid rgba(0, 0, 0, 0.164);
}

.notify:hover {
    background-color: rgba(0, 0, 0, 0.096) !important;
}

.notify_image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    align-self: flex-start;
    /* border: 1px solid rgba(0, 0, 0, 0.315); */
}

.notify_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.notify_time {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;
    color:rgba(51, 51, 51, 0.719)
}

.showNotifications {
    animation: showNotifications linear 100ms forwards;
}

.hideNotifications {
    animation: hideNotifications linear 100ms forwards;
}

.showNot {
    display: block !important;
}

.hideNot {
    display: none !important;
}

@keyframes hideNotifications {
    from {
        width: 300px;
        max-height: 500px;
        overflow-y: auto;
        opacity: 1;
        /* display: block !important; */
    }
    to {
        width: 0px;
        max-height: 0px ;
        overflow-y: hidden;
        opacity: 0;
        /* display: none !important; */
    }
}

@keyframes showNotifications {
    from {
        width: 0px;
        max-height: 0px ;
        overflow-y: hidden;
        opacity: 0;
        /* display: none !important; */
    }
    to {
        width: 300px;
        max-height: 500px;
        overflow-y: auto;
        opacity: 1;
        /* display: block !important; */
    }
}


@media (max-width: 660px) {
    .notifications_container {
        left: auto;
        right: 0px;
    }
}

@media (max-width: 540px) {
    .notifications_container {
        /* left: auto; */
        right: -100px;
    }
    .notify_time {
        font-size: .9rem;
    }

    .text-end {
        font-size: 1rem;
    }
}

@media (max-width:375px ) {
    .notifications_container {
        /* left: auto; */
        /* right: -180px; */
        position: fixed;
        right: 10px;
        top: 70px;
    }
}

@media (max-width:330px ) {
    .notifications_container {
        max-width: 275px;
    }
}

@media (max-width:301px ) {
    .notifications_container {
        max-width: 250px;
    }
}

/* screen smaller than 992 */
@media  (max-width: 992px)
{
    .navbar ul 
    {
        padding: 0px;
    }
    .navbar ul > li 
    {
        margin: 4px auto !important;
    
    } 
} 


@media (max-width:992px){
.navbar .signparent
{
    margin: 0px auto;
}

}
.navbar li 
{
    margin-bottom: 4px;
}
.navbar ul  li a
{
    border-radius: 20px;
    transition: color 500ms linear,background-color 300ms linear;
} 
.navbar ul  li a:hover 
{
    background-color: #11AEA2;
    color: white !important;
}



/* edit arrow dropdown  */
.dropdown-toggle
{
    border:1px solid #0000000e; 
    border-radius: 50%;
}

.dropdown-toggle:hover 
{
    border:1px solid #11AEA2;
    border-radius: 50%;
    
}
.dropdown-toggle::before {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
/* reset arrow dropdown  */
.dropdown-toggle::after
{
    display:none !important
}

/* text align  anchore of  user and notification list */
.notification  ul li > a , .user ul li > a
{
    text-align: right ;
    border-bottom:1px solid #11AEA2;
}

.notification  ul li:last-child  a:last-child , .user ul li:last-child  a:last-child
{
   
    border-bottom:none;
}

.notification  ul li > a:hover , .user ul li > a:hover
{
    text-align: right !important ;
    border-bottom:1px solid #11aea1b2;
}

@media screen and (max-width:325px) {
    
.navbar .LOGO {
    margin-left:auto !important;
    /* margin-right:auto !important; */
    }
}

.navbar .Toggler_Icon {
    /* margin-left:auto !important; */
    /* margin-right:auto !important; */
    margin-left: 10px !important;
    }
@media (max-width:380px ) {
    .navbar .Toggler_Icon {
        /* margin-left:auto !important; */
        /* margin-right:auto !important;
        margin-left: 10px !important; */
        /* margin: 0px auto !important; */
        }
    }
    





.faqs_btn_container {
    position: fixed;
    bottom: 20px;
    right: 50px;
    user-select: none;
    z-index: 9999;
    cursor: pointer;
    color: black    ;
} 

.faqs_btn_container:hover img{
    scale: .9;
} 

.faqs_btn_container:hover {
    color: black;
}

.faqs_btn_container .content {
    position: relative;
    width: 90px;
    height: 90px;
}

.faqs_btn_container .content img {
    /* box-shadow: ; */
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: 4px 20px 5px black;
    transition: all 100ms ease-in;
}

.faqs_btn_container .content .questions_word {
    position: absolute;
    right: -30px;
    top: 0%;
    transform: rotate(45deg) ;
    font-weight: 900;
    transition: all 100ms ease-in;
}
.faqs_btn_container .content .freq_word {
    position: absolute;
    left: -25px;
    top: 0%;
    transform: rotate(-45deg) ;
    font-weight: 900;
    transition: all 100ms ease-in;
}
