.custom-link {
    color: #25918f;
    text-decoration: underline;
    cursor: pointer;
    transition: opacity 0.3s, color 0.3s;
  }
  
  .custom-link.disabled {
    color: gray;
    text-decoration: none;
    cursor: not-allowed;
    opacity: 0.6;
  }